<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col class="text-center">
        <p>{{ text }}</p>

        <v-progress-circular v-if="loggedIn === null" indeterminate />
        <v-btn v-else @click="postLoggingInAction">{{ action_text }}</v-btn>
      </v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "login-callback",

  props: {
    provider: String
  },

  data() {
    return {
      text: 'Please wait, while we log you in...',
      action_text: 'Continue',
      success_redirect: {
        name: 'home',
        query: {
          ref: 'login'
        }
      }
    }
  },

  async created() {
    let access_token = this.$route.query.access_token;
    let provider = this.provider;

    await this.$store.dispatch('auth/login', { access_token, provider });
  },

  methods: {
    async postLoggingInAction()
    {
      await new Promise(r => setTimeout(r, 3000));

      if (this.loggedIn)
        return await this.$router.push(this.success_redirect); // redirect after login
      else
        return await this.$router.push({name: 'login', query: { ref: 'failed_login' }}); // redirect back to login
    }
  },

  computed: {
    ...mapGetters({
      loggedIn: 'auth/isLoggedIn'
    })
  },

  watch: {
    loggedIn() {
      // if loggedIn === null, we are currently trying to log in (neither true or false yet)

      if (this.loggedIn === true) { // the login was successful
        this.text = "Login successful! You are being redirected...";

        let redirect = localStorage.getItem('redirect-after-login');
        if (redirect)
          this.success_redirect = {
            path: redirect,
            query: {
              ref: 'login'
            }
          };

        localStorage.removeItem('redirect-after-login');

        this.postLoggingInAction(); // redirect user
      }

      if (this.loggedIn === false) {
        this.text = "Login failed. Please try again."; // inform user of failure
        this.action_text = "Login";
      }
    }
  }
}
</script>
