<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col cols="12" sm="9" md="6" lg="5" xl="4">
        <h1>Account</h1>

        <p class="mt-3" v-if="typeof user.provider !== 'undefined' && user.provider !== null">
          Registered via {{ user.provider | capitalize }}.
        </p>

        <v-simple-table class="mt-3">
          <tbody>
          <tr>
            <td>Username</td>
            <td>{{ user.username }}</td>
          </tr>
          <tr>
            <td>E-Mail</td>
            <td>
              {{ user.email }}
              <v-chip v-if="user.confirmed" color="green" small>confirmed</v-chip>
              <v-chip v-else color="red" small>not confirmed</v-chip>
            </td>
          </tr>
          <tr>
            <td>Registered since</td>
            <td>{{ user.createdAt | moment('LL') }}</td>
          </tr>
          </tbody>
        </v-simple-table>

        <div class="mt-5">
          <v-btn v-if="user.application" :to="{ name: 'apply' }">My Application</v-btn>
          <v-btn class="float-right" :to="{ name: 'logout' }">Logout</v-btn>
        </div>
      </v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },

  filters: {
    capitalize: function (value) {
      if (!value)
        return '';

      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
}
</script>

<style scoped>

</style>
