<template>
  <v-container>
    <v-row>
      <v-spacer/>
      <v-col class="text-center">
        <p>
          Please wait, while we log you out...
        </p>

        <v-progress-circular indeterminate/>
      </v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  async created() {
    await this.$store.dispatch('auth/logout');

    // TODO: add timeout in case this.loggedIn never changes
  },

  computed: {
    ...mapGetters({
      loggedIn: 'auth/isLoggedIn',
    })
  },

  watch: {
    async loggedIn() {
      if (this.loggedIn !== false)
        return;

      await new Promise(r => setTimeout(r, 1000));

      await this.$router.push({name: 'home', query: {ref: 'logout'}});
    }
  }
}
</script>
