<template>
  <v-container>
    <v-row justify="space-around">
      <login-form />
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from "@/components/auth/LoginForm.vue";

export default {
  components: {LoginForm},
}
</script>
